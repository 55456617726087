import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'

import consumer_t_od_hotel_order_interim_hotelCancelOrderList from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelCancelOrderList'
import consumer_t_od_hotel_order_interim_hotelCancelOrderStatistics from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelCancelOrderStatistics'
import consumer_t_od_hotel_order_interim_downloadHotelCancelOrderListDetails from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_downloadHotelCancelOrderListDetails'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'
export default {
    data() {
        return {
            loading: false,
            searchForm: {
                deptIds: [],
                deptIdList: [],
                startDate: '',
                endDate: '',
                person: '', //
                feeAffiliation: '', //
                keyword: '',
                orderBy: '',
                refOrderNo: '',
                orderNo: '',   // 订单号
                cancelStatus: 0, // 退订状态(0：退订中，1：退订成功，3：退订失败,-1:默认)

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            },
            applyTime: [],
            deptList: [],
            totalCount: 0,   //总条数
            activeName: '-1',
            tabList: [
                // 退订状态(0：退订中，1：退订成功，3：退订失败,-1:默认)
                {
                    name: "-1",
                    label: "全部",
                    value: 0
                },
                {
                    name: "0",
                    label: "退订中",
                    value: 0
                },
                {
                    name: "3",
                    label: "退订失败",
                    value: 0
                },
                {
                    name: "1",
                    label: "退订成功",
                    value: 0
                },
            ],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.getDeptList();
        this.getOrderList()
        this.getListNum()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.startDate = moment(val[0]).format('YYYY-MM-DD 00:00:00')
                    this.searchForm.endDate = moment(val[1]).format('YYYY-MM-DD 23:59:59')
                } else {
                    this.searchForm.startDate = ''
                    this.searchForm.endDate = ''
                }
            }
        },
    },
    computed: {},
    filters: {},
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        onSearch() {
            this.searchForm.currentPage = 1
            this.getOrderList()
            this.getListNum()
        },
        onReset() {
            this.searchForm = {
                deptIds: [],
                deptIdList: [],
                startDate: '',
                endDate: '',
                person: '', //
                feeAffiliation: '', //
                keyword: '',
                orderBy: '',
                refOrderNo: '',
                orderNo: '',   // 订单号
                cancelStatus: 0, // 退订状态(0：退订中，1：退订成功，3：退订失败,-1:默认)

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            }
            this.onSearch()
        },
        getOrderList() {
            this.loading = true
            this.searchForm.cancelStatus = parseInt(this.activeName)
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
            consumer_t_od_hotel_order_interim_hotelCancelOrderList(this.searchForm).then(res => {
                this.orderList = res.datas.list
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.orderList = []
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            })
        },
        getListNum() {
            let {cancelStatus, ...params} = this.searchForm;
            consumer_t_od_hotel_order_interim_hotelCancelOrderStatistics(params).then(res => {
                const hotelOrderNum = res.datas.list
                this.tabList.forEach(value => {
                    hotelOrderNum.forEach(value1 => {
                        if (value1.cancelStatus.toString() === value.name) {
                            value.value = value1.orderNum
                        }
                    })
                })
            })
        },
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false;
            let {
                orderNo,
                person,
                startDate,
                endDate,
            } = this.searchForm;
            let params = {
                orderNo: orderNo,
                person: person,
                startDate: startDate,
                endDate: endDate,
                cancelStatus: Number(this.activeName)
            }
            consumer_t_od_hotel_order_interim_downloadHotelCancelOrderListDetails(params).then(res => {
                let url = res.datas.orderDetailsUrl
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    })
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-hotel-refund-detail',
                query: {
                    orderNo: val.orderNo
                }
            })
        },
        getStatusToClass(val) {
            // 退订状态(0：退订中，1：退订成功，3：退订失败,-1:默认)
            if (val === 0) {
                return 'yellow-text'
            } else if (val === 1) {
                return 'green-text'
            } else {
                return 'red-text'
            }
        },
    }
}
